import { useTheme } from "../../contexts/theme/themeContext";
import Spinner from "./Spinner";

function SpinnerFullPage() {
  const theme = useTheme();
  return (
    <div
      className="spinner--fullpage"
      style={{
        backgroundColor: theme.theme.palette.primary.background,
      }}
    >
      <Spinner />
    </div>
  );
}

export default SpinnerFullPage;
