import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./contexts/theme/themeContext";
import { lazy, Suspense } from "react";
import { LanguageProvider } from "./contexts/localization/languageContext";
import SpinnerFullPage from "./components/partials/SpinnerFullPage";

const Main = lazy(() => import("./pages/Main"));
const Contacts = lazy(() => import("./pages/Contacts"));
const Rules = lazy(() => import("./pages/Rules"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Rooms = lazy(() => import("./pages/Rooms"));
const AppLayout = lazy(() => import("./pages/AppLayout"));

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <Router>
          <Suspense fallback={<SpinnerFullPage />}>
            <Routes>
              <Route path="/" element={<AppLayout />}>
                <Route index path="/" element={<Main />} />
                <Route path="rooms" element={<Rooms />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="rules" element={<Rules />} />
                <Route path="gallery" element={<Gallery />} />
              </Route>
              <Route path="*" element={<>404 - Page Not Found</>} />
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
